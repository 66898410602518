import { useStore } from 'vuex'
import { format } from 'date-fns'
import { paramCase } from 'param-case'
import { snakeCase } from 'snake-case'
import { titleCase } from 'title-case'
import formatHighlight from 'json-format-highlight'

export default () => {

  const store = useStore()

  const addThousandsSeparators = (number) => {
    return `${number}`.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, store.state.app.thousandsSeparator)
  }

  const formatNumber = (number, decimals = undefined, prefix = '', truncateZeros = true) => {

    let newValue = `${number}`
      .replace(store.state.app.disallowedCurrencyCharactersRegex, '')
      .replace(/^0*/, '')

    if (newValue === '') newValue = '0'

    const decimalPosition = newValue.indexOf(store.state.app.decimalSeparator)

    if (decimalPosition === -1) {
      newValue = addThousandsSeparators(newValue)

    } else {

      const leftSide = addThousandsSeparators(newValue.substring(0, decimalPosition) || '0')

      const rightSide = newValue
        .substring(decimalPosition + 1, typeof decimals === 'number' ? decimalPosition + 1 + decimals : newValue.length)
        .replaceAll(store.state.app.decimalSeparator, '')
        .replace(/(0*)$/, truncateZeros ? '' : '$1')

      newValue = `${leftSide}${store.state.app.decimalSeparator}${rightSide}`

      // this handles the case where truncating zeros leaves the number with
      //  only the decimal separator at the end (i.e. "12.00" -> "12."), which
      //  is weird so we'll truncate the decimal separator in that case as well
      if (!rightSide && truncateZeros) {
        newValue = leftSide
      } else {
        newValue = `${leftSide}${store.state.app.decimalSeparator}${rightSide}`
      }

    }

    return `${prefix || ''}${newValue}`

  }

  const formatMaxEventsMonthly = (stripePlan) => {
    return formatNumber(Number.parseInt(stripePlan.metadata.maxEventsMonthly, 10))
  }

  // @TODO: this could be written a bit more robustly to handle cases where time
  //  limits are more than a year
  const formatMaxEventsTimeframe = (stripePlan, singular = false) => {
    return stripePlan.metadata.maxEventsTimeframe !== '31536000000'
      ? `${formatNumber(stripePlan.metadata.maxEventsTimeframe / 86400000)} ${singular ? 'day' : 'days'}`
      : '1 year'
  }

  return {

    paramCase,
    snakeCase,
    titleCase,
    formatNumber,
    addThousandsSeparators,

    formatMaxEventsMonthly,
    formatMaxEventsTimeframe,

    highlightJSON(json) {
      return formatHighlight(json, {
        keyColor: '#b694ff',
        numberColor: '#80d0ff',
        stringColor: '#ddd6ff',
        trueColor: '#98f093',
        falseColor: '#80d0ff',
        nullColor: '#80d0ff',
      })
    },

    truncateAddress(address = '') {
      return address.replace(/^(.{6}).*(.{4})$/, '$1...$2')
    },

    formatNumberAsUSD(number) {
      // ensure that numbers like 10.1 are formatted as "$10.10" and not "$10.1"
      const formattedNumber = formatNumber(number, 2, '$', false)
      return formattedNumber.match(/\.\d$/)
        ? `${formattedNumber}0`
        : formattedNumber
    },

    formatTimestamp(timestamp, includeTime = true) {
      if (!timestamp) return null
      return includeTime
        ? format(new Date(timestamp), 'dd MMM, yyyy h:mm a')
        : format(new Date(timestamp), 'dd MMM')
    },

  }

}
